window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import SelectCommune from "./components/SelectCommune";
import { VRow } from "vuetify/lib";
import VueCookies from "vue-cookies";
import wysiwyg from "vue-wysiwyg";

// Request

window.request = async function (method, url, data) {
  try {
    let response;
    if (method === "get") {
      response = await axios.get(url);
    } else if (method === "post") {
      response = await axios.post(url, data);
    } else if (method === "delete") {
      response = await axios.delete(url);
    } else if (method === "put") {
      response = await axios.put(url, data);
    }
    store.state.ready = true;
    return response.data;
  } catch (e) {
    if (e.response) {
      if (e.response.status === 401 || e.response.status === 419) {
        router.push("/login");
      }
      return { error: true, message: e.response.data.message };
    }
  }
};

moment.locale("fr");

Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(wysiwyg, {});

Vue.component("select-commune", SelectCommune);
Vue.component("v-row", VRow);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
