import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/properties",
    name: "properties",
    component: () => import(/* webpackChunkName: "properties" */ "../views/Properties.vue"),
  },
  {
    path: "/new-property",
    name: "new-property",
    component: () => import(/* webpackChunkName: "new-property" */ "../views/NewProperty.vue"),
  },
  {
    path: "/edit-property/:id",
    component: () => import(/* webpackChunkName: "edit-properties" */ "../views/EditProperty.vue"),
  },
  {
    path: "/archives",
    name: "archives",
    component: () => import(/* webpackChunkName: "archives" */ "../views/Archives.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import(/* webpackChunkName: "contacts" */ "../views/Contacts.vue"),
  },
  {
    path: "/edit-contact/:id",
    component: () => import(/* webpackChunkName: "edit-contact" */ "../views/EditContact.vue"),
  },
  {
    path: "/new-contact",
    component: () => import(/* webpackChunkName: "new-contact" */ "../views/NewContact.vue"),
  },
  {
    path: "/en-attente",
    component: () => import(/* webpackChunkName: "en-attente" */ "../views/EnAttente.vue"),
  },
  {
    path: "/emails-automatique",
    component: () => import(/* webpackChunkName: "emails-automatique" */ "../views/Emails.vue"),
  },
  {
    path: "/new-email-automatique",
    component: () => import(/* webpackChunkName: "new-email-automatique" */ "../views/NewEmails.vue"),
  },
  {
    path: "/edit-email-automatique/:id",
    component: () => import(/* webpackChunkName: "edit-email-automatique" */ "../views/EditEmail.vue"),
  },
  {
    path: "/matching-property/:id",
    component: () => import(/* webpackChunkName: "property-matching" */ "../views/PropertyMatching.vue"),
  },
  {
    path: "/matching-contact/:id",
    component: () => import(/* webpackChunkName: "contact-matching" */ "../views/ContactMatching.vue"),
  },
  {
    path: "/factures",
    component: () => import(/* webpackChunkName: "factures" */ "../views/Factures.vue"),
  },
  {
    path: "/edit-facture/:id",
    component: () => import(/* webpackChunkName: "edit-facture" */ "../views/EditFacture.vue"),
  },
  {
    path: "/new-facture/:id",
    component: () => import(/* webpackChunkName: "new-facture" */ "../views/NewFacture.vue"),
  },
  {
    path: "/new-facture",
    component: () => import(/* webpackChunkName: "new-facture" */ "../views/NewFacture.vue"),
  },
  {
    path: "/comparaisons",
    component: () => import(/* webpackChunkName: "comparaisons" */ "../views/Comparaisons.vue"),
  },
  {
    path: "/edit-pc/:id",
    component: () => import(/* webpackChunkName: "edit-comparaison" */ "../views/EditComparaison.vue"),
  },
  {
    path: "/new-pc",
    component: () => import(/* webpackChunkName: "new-comparaison" */ "../views/NewComparaison.vue"),
  },
  {
    path: "/challenge",
    component: () => import(/* webpackChunkName: "challenge" */ "../views/Challenge.vue"),
  },
  {
    path: "/confirmations",
    component: () => import(/* webpackChunkName: "confirmations" */ "../views/Confirmations.vue"),
  },
  {
    path: "/collaborateurs",
    component: () => import(/* webpackChunkName: "collaborateurs" */ "../views/Collaborateurs.vue"),
  },
  {
    path: "/edit-collaborateur/:id?",
    component: () => import(/* webpackChunkName: "edit-collaborateur" */ "../views/EditCollaborateur.vue"),
  },
  {
    path: "/planifications/:id?",
    component: () => import(/* webpackChunkName: "planifications" */ "../views/Planifications.vue"),
  },
  {
    path: "/suivi",
    component: () => import(/* webpackChunkName: "suivi" */ "../views/Suivi.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
]

const router = new VueRouter({
  routes
})

export default router
