<template>
  <v-app>
    <v-navigation-drawer v-if="$store.state.ready" fixed permanent clipped app>
      <div class="logo">
        <a href="https://www.immobilieregeorges.be" target="_blank"><img :src="require('./assets/img/logo.png')" /></a>
      </div>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in menuItems" :key="item.title" @click="$router.replace(item.slug)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Déconnexion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <p style="text-align:center; font-family: 'Pacifico', cursive; padding: 40px; color: #c1212e;">La satisfaction du client , même le plus exigeant !</p>
    </v-navigation-drawer>

    <v-main class="grey lighten-5">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    menuItems: [
      { title: "Dashboard", icon: "mdi-view-dashboard", slug: "/" },
      { title: "Biens", icon: "mdi-home-group", slug: "/properties" },
      { title: "Archives", icon: "mdi-archive", slug: "/archives" },
      { title: "Contacts", icon: "mdi-account-group", slug: "/contacts" },
      { title: "En attente", icon: "mdi-account-multiple-plus", slug: "/en-attente" },
      { title: "Collaborateurs", icon: "mdi-sitemap", slug: "/collaborateurs" },
      { title: "Email auto", icon: "mdi-email", slug: "/emails-automatique" },
      { title: "Confirmations", icon: "mdi-cellphone-message", slug: "/confirmations" },
      { title: "Planifications", icon: "mdi-calendar-check", slug: "/planifications" },
      { title: "Challenge", icon: "mdi-sword-cross", slug: "/challenge" },
      { title: "Factures", icon: "mdi-receipt", slug: "/factures" },
      { title: "PC", icon: "mdi-clipboard-multiple", slug: "/comparaisons" },
      { title: "Suivi", icon: "mdi-clipboard-arrow-right", slug: "/suivi" },
       
    ],
  }),
  methods: {
    async logout() {
      const data = await request("post", "/logout");
      if(!data.error){
        this.$router.push('/login');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.logo {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  img {
    width: 100px;
  }
}
</style>
