<template>
  <div>
    <v-combobox
      :items="villes"
      label="Commune"
      item-text="name"
      item-value="name"
      :loading="loading"
      :value="value"
      :multiple="multiple"
      :cache-items="true"
      :clearable="clearable"
      @input="$emit('input', $event.name ? $event.name : $event)"
    ></v-combobox>
  </div>
</template>

<script>
export default {
  data: () => ({
    villes: [],
    loading: false,
  }),
  props: ["value", "multiple", "clearable"],

  mounted() {
    this.getVille();
  },
  methods: {
    async getVille() {
      if (this.villes.length === 0) {
        this.loading = true;
        const data = await request("get", "api/villes");
        if (!data.error) {
          let groups = [];
          for (let ville of data) {
            if (!groups.includes(ville.group)) {
              groups.push(ville.group);
            }
          }
          for (let group of groups) {
            this.villes.push({ header: group });
            for (let ville of data) {
              if (ville.group === group) {
                this.villes.push(ville);
              }
            }
          }
          this.loading = false;
        }
      }
    },
  },
};
</script>
